import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, colors, fontSizes, radius } from 'theme'
import { IMAGE } from 'consts'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Input from 'components/atoms/Input'
import Switch from 'components/atoms/Switch'
import TextArea from 'components/atoms/TextArea'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import DropDown from 'shared/DropDown'
import {
  IMAGE_LINK_THEME,
  DEFAULT_THEME_CONTENT_WIDTH,
  DEFAULT_THEME_CORNER_RADIUS,
  DEFAULT_THEME_SCALE,
  DEFAULT_THEME_SHADOW,
  DEFAULT_THEME_TRANSPARENCY,
  VISTA_PAGE_THEME_TEMPLATE_DEFAULT,
  DEFAULT_IMAGE_LINK_IMAGE_SCALE,
  SCALE_CONSTANT,
  DEFAULT_THEME_TEXT_FONT_WEIGHT,
  DEFAULT_THEME_TEXT_FONT_STYLE,
  LINK_TYPE_EXTERNAL,
  LINK_TYPE_INTERNAL,
  LINK_TYPES,
  PAGE_BLOCK_ID_START,
} from 'routes/VistaPage/consts'
// eslint-disable-next-line import/no-cycle
import {
  collectItemsForInternalLinks,
  getBackgroundStyleString,
  getTextFontStyleString,
  getTextFontWeightString,
} from 'routes/VistaPage/helper'
import ThemeComponent from 'routes/VistaPage/VistaPageDetail/components/ThemeComponent'
import FormatSelectInternalBlockOption from 'routes/VistaPage/VistaPageDetail/components/components/FormatSelectInternalBlockOption'
import MediaUploadComponent from '../MediaUploadComponent'

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -9px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  z-index: 2;
`

const StyledApplyDefaultThemeText = styled(Text)`
  color: ${colors.primary};
  font-size: ${fontSizes.xs};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const StyledTextAreaWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${({ $fontSize }) => $fontSize}em;
  box-shadow: rgb(145 147 169 / 85%) 0px 0px ${({ $boxShadow }) => $boxShadow}em;
  overflow: hidden;
  cursor: pointer;
  position: relative;
`

const StyledTextAreaBackground = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  ${({ $background }) => $background};
  opacity: ${({ $transparency }) => ($transparency ? 1 - $transparency : 1)};
`

const StyledText = styled(Text)`
  box-sizing: border-box;
  white-space: break-spaces;
  z-index: 1;
  ${({ $color }) => $color};
  ${({ $fontStyle }) => $fontStyle};
  ${({ $fontWeight }) => $fontWeight};
`

const PostMediaWrapper = styled(Flex)`
  display: flex;
  cursor: pointer;
  border-radius: ${radius.l};
  position: relative;
  flex-direction: column;
  text-align: center;
  ${({ $border }) => $border && `border: 1px solid ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};`}
`

const { MEDIAS, TEXT, ALT_TEXT, LINK_TYPE, LINK_EXTERNAL, LINK_INTERNAL, DETAILED_APPEARANCE_THEME } = {
  MEDIAS: 'medias',
  TEXT: 'text',
  ALT_TEXT: 'alt_text',
  LINK_TYPE: 'link_type',
  LINK_EXTERNAL: 'link',
  LINK_INTERNAL: 'link_internal',
  DETAILED_APPEARANCE_THEME: 'detailed_appearance_theme',
}

const FormValidationSchema = () => {
  return Yup.object().shape({
    [TEXT]: Yup.string().max(1000, `Text is too long - should be 1000 chars maximum.`),
    [ALT_TEXT]: Yup.string().max(300, `Alt text is too long - should be 300 chars maximum.`),
  })
}

const ImageLinkComponent = ({
  user,
  data,
  vistaPage,
  isEditable,
  handleChangeBlockComponent,
  handleCollectTrackingData,
}) => {
  const [pageItems, setPageItems] = useState([])

  const {
    page_id,
    _id: block_id,
    data: {
      medias = [],
      text = '',
      link: link_external = '',
      link_type = LINK_TYPE_EXTERNAL,
      link_internal = null,
      alt_text = '',
      detailed_appearance_theme = false,
    } = {},
  } = data

  useEffect(() => {
    if (isEditable) {
      const { items_transformed } = collectItemsForInternalLinks({ vistaPage, page_id, block_id })
      setPageItems([...items_transformed])
    }
  }, [])

  const { 0: media } = medias

  const { url, thumbnail_url = '/assets/landscape.svg', isNew, type = IMAGE } = media || {}

  const { appearance = VISTA_PAGE_THEME_TEMPLATE_DEFAULT } = vistaPage

  const appearance_theme = appearance[IMAGE_LINK_THEME] || {}

  const theme = data.data ? data.data[IMAGE_LINK_THEME] || appearance_theme : appearance_theme

  const has_theme_changed = !!(data.data && data.data[IMAGE_LINK_THEME])

  const {
    background = appearance_theme.background,
    image_link_image_scale = appearance_theme.image_link_image_scale || DEFAULT_IMAGE_LINK_IMAGE_SCALE,
    text_scale = appearance_theme.scale || DEFAULT_THEME_SCALE,
    text_color = appearance_theme.text_color,
    text_font_weight = appearance_theme.text_font_weight || DEFAULT_THEME_TEXT_FONT_WEIGHT,
    text_font_style = appearance_theme.text_font_style || DEFAULT_THEME_TEXT_FONT_STYLE,
    content_width = appearance_theme.content_width || DEFAULT_THEME_CONTENT_WIDTH,
    corner_radius = appearance_theme.corner_radius || DEFAULT_THEME_CORNER_RADIUS,
    shadow = appearance_theme.shadow || DEFAULT_THEME_SHADOW,
    transparency = appearance_theme.transparency || DEFAULT_THEME_TRANSPARENCY,
  } = theme || {}

  const { background_color } = background || {}
  const textarea_background = getBackgroundStyleString({ background_color })

  const { color } = text_color || {}
  const text_font_color = getBackgroundStyleString({ background_color: color, type: 'text' })
  const text_font_style_string = getTextFontStyleString({ style: text_font_style })
  const text_font_weight_string = getTextFontWeightString({ weight: text_font_weight })

  return (
    <Fragment>
      {isEditable ? (
        <Flex flexDirection="column" width="100%" height="100%">
          <Flex justifyContent="space-between" alignItems="flex-start">
            {media ? (
              <PostMediaWrapper width="80px" height="80px" $border>
                <CloseIconWrapper
                  onClick={() => {
                    handleChangeBlockComponent({ page_id, block_id, key: MEDIAS, value: [] })
                  }}
                >
                  <Image width="10px" height="10px" src="/assets/clear.svg" />
                </CloseIconWrapper>
                {isNew ? (
                  <Flex alignItems="center" justifyContent="center" width="80px" height="80px">
                    <ClipLoader size="40" color={colors.primary} />
                  </Flex>
                ) : (
                  <Fragment>
                    {type === IMAGE && (
                      <ImageWithFallback
                        source={thumbnail_url || url}
                        fallbackSource={url}
                        width="100%"
                        height="100%"
                        borderRadius={radius.l}
                        objectFit="contain"
                      />
                    )}
                  </Fragment>
                )}
              </PostMediaWrapper>
            ) : (
              <Flex />
            )}

            <MediaUploadComponent
              user={user}
              vistaPage={vistaPage}
              handleChangeBlockComponent={handleChangeBlockComponent}
              data={{ page_id, block_id, medias, medias_storage_key: MEDIAS }}
              maxAttachmentsAllowed={1}
            />
          </Flex>

          {media && (
            <Formik
              initialValues={{
                [TEXT]: text,
                [ALT_TEXT]: alt_text,
                [LINK_TYPE]: link_type || LINK_TYPES[0].value,
                [LINK_EXTERNAL]: link_external,
                [LINK_INTERNAL]: link_internal,
              }}
              validationSchema={FormValidationSchema}
              onSubmit={() => {}}
              autocomplete="off"
              validateOnBlur
            >
              {({ values, errors, setFieldValue }) => {
                return (
                  <Box width="100%">
                    <Box mt="m">
                      <TextArea
                        rows={3}
                        placeholder="Image caption"
                        defaultValue={text}
                        onChange={(event) => {
                          handleChangeBlockComponent({ page_id, block_id, key: TEXT, value: event.target.value })
                          setFieldValue(TEXT, event.target.value)
                        }}
                        error={values[TEXT] && errors[TEXT]}
                        width="100%"
                      />
                    </Box>

                    <Box mt="m">
                      <Input
                        placeholder="Alt text"
                        type="text"
                        defaultValue={alt_text}
                        onChange={(event) => {
                          handleChangeBlockComponent({ page_id, block_id, key: ALT_TEXT, value: event.target.value })
                          setFieldValue(ALT_TEXT, event.target.value)
                        }}
                        error={values[ALT_TEXT] && errors[ALT_TEXT]}
                        width="100%"
                      />
                    </Box>

                    <Box mt="m">
                      <DropDown
                        placeholder="Select link type"
                        value={values[LINK_TYPE] && LINK_TYPES.find(({ value }) => value === values[LINK_TYPE])}
                        onChange={(option) => {
                          if (option) {
                            handleChangeBlockComponent({ page_id, block_id, key: LINK_TYPE, value: option.value })
                            setFieldValue(LINK_TYPE, option.value)
                          }
                        }}
                        options={LINK_TYPES}
                        openMenuOnFocus
                        isSearchable={false}
                      />
                    </Box>

                    {values[LINK_TYPE] === LINK_TYPE_EXTERNAL && (
                      <Box mt="m">
                        <Input
                          placeholder="Image link"
                          type="text"
                          defaultValue={values[LINK_EXTERNAL]}
                          onChange={(event) => {
                            handleChangeBlockComponent({
                              page_id,
                              block_id,
                              key: LINK_EXTERNAL,
                              value: event.target.value,
                            })
                            setFieldValue(LINK_EXTERNAL, event.target.value)
                          }}
                          error={values[LINK_EXTERNAL] && errors[LINK_EXTERNAL]}
                          width="100%"
                        />
                      </Box>
                    )}

                    {values[LINK_TYPE] === LINK_TYPE_INTERNAL && (
                      <Box mt="m">
                        <DropDown
                          placeholder="Select page block"
                          value={
                            values[LINK_INTERNAL] && pageItems.length
                              ? pageItems.find(({ value }) => value === values[LINK_INTERNAL])
                              : null
                          }
                          onChange={(option) => {
                            if (option) {
                              handleChangeBlockComponent({ page_id, block_id, key: LINK_INTERNAL, value: option.value })
                              setFieldValue(LINK_INTERNAL, option.value)
                            }
                          }}
                          options={pageItems}
                          openMenuOnFocus
                          isSearchable={false}
                          formatOptionLabel={(data) => FormatSelectInternalBlockOption(data)}
                          error={values[LINK_INTERNAL] && errors[LINK_INTERNAL]}
                        />
                      </Box>
                    )}
                  </Box>
                )
              }}
            </Formik>
          )}

          <Flex mt="l" alignItems="center" justifyContent="space-between">
            <Switch
              isOn={detailed_appearance_theme}
              onClick={() => {
                handleChangeBlockComponent({
                  page_id,
                  block_id,
                  key: DETAILED_APPEARANCE_THEME,
                  value: !detailed_appearance_theme,
                })
              }}
              text="Edit theme"
              color={COLOR_CONSTANTS.DENIM}
              fontWeight="normal"
            />

            {detailed_appearance_theme && has_theme_changed && (
              <StyledApplyDefaultThemeText
                onClick={() => {
                  handleChangeBlockComponent({ page_id, block_id, key: IMAGE_LINK_THEME, value: null })
                }}
              >
                Apply default page theme
              </StyledApplyDefaultThemeText>
            )}
          </Flex>

          {detailed_appearance_theme && (
            <Flex flexDirection="column" mt="m">
              <ThemeComponent
                handleChangeParameter={({ key, value }) => {
                  handleChangeBlockComponent({ page_id, block_id, type: IMAGE_LINK_THEME, key, value })
                }}
                data={theme}
                showBackgroundColorSettingsPaletteTypeMedia={false}
                showHeaderMediasScaleSettings={false}
                showHeaderFormatSettings={false}
                showSocialLinksImagesScaleSettings={false}
                showSocialLinksColoredSettings={false}
              />
            </Flex>
          )}
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="center" width="100%" flexDirection="column">
          <PostMediaWrapper
            width={`${image_link_image_scale * 10}%`}
            maxWidth="100%"
            as={link_type === LINK_TYPE_EXTERNAL && link_external ? 'a' : 'div'}
            href={link_type === LINK_TYPE_EXTERNAL && link_external ? link_external : ''}
            target={link_type === LINK_TYPE_EXTERNAL && link_external ? '_blank' : ''}
            alt={text}
            onClick={() => {
              if (link_type === LINK_TYPE_INTERNAL && link_internal) {
                const el = document.getElementById(`${PAGE_BLOCK_ID_START}${link_internal}`)
                if (el) {
                  el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
              }
              handleCollectTrackingData()
            }}
          >
            {isNew ? (
              <Flex alignItems="center" justifyContent="center" width="100%" height="100px">
                <ClipLoader size="50" color={colors.primary} />
              </Flex>
            ) : (
              <Fragment>
                {type === IMAGE && (
                  <ImageWithFallback
                    source={url || '/assets/landscape.svg'}
                    fallbackSource="/assets/landscape.svg"
                    width="100%"
                    height="100%"
                    borderRadius={text ? `${radius.l} ${radius.l} 0 0` : radius.l}
                    alt={alt_text}
                    objectFit="contain"
                  />
                )}
              </Fragment>
            )}
          </PostMediaWrapper>
          {text && (
            <StyledTextAreaWrapper
              p={`${20 * SCALE_CONSTANT}px`}
              width={`${content_width}%`}
              $fontSize={text_scale}
              borderRadius={`0 0 ${corner_radius}em ${corner_radius}em`}
              $boxShadow={shadow}
            >
              <StyledTextAreaBackground $background={textarea_background} $transparency={transparency} />
              <StyledText
                fontSize={`${0.95}em`}
                textAlign="center"
                $color={text_font_color}
                $fontWeight={text_font_weight_string}
                $fontStyle={text_font_style_string}
              >
                {text}
              </StyledText>
            </StyledTextAreaWrapper>
          )}
        </Flex>
      )}
    </Fragment>
  )
}

ImageLinkComponent.defaultProps = {
  user: {},
  isEditable: false,
  handleChangeBlockComponent: () => {},
  handleCollectTrackingData: () => {},
  isPublic: false,
}

ImageLinkComponent.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object.isRequired,
  vistaPage: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  handleChangeBlockComponent: PropTypes.func,
  handleCollectTrackingData: PropTypes.func,
  isPublic: PropTypes.bool,
}

ImageLinkComponent.displayName = 'ImageLinkComponent'

export default ImageLinkComponent
